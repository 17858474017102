import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import { Button } from '../../../Molecules/Button/Button';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Carousel from '../../../components/Carousel/Carousel';
import DocumentSection from '../../../components/ContentType/Section/DocumentSection/DocumentSection';
import {
  getBreadCrumb,
  getReOrderedCarouselSlides,
  getSectionBG,
  getTextColor,
  removeHtmlTag,
  tradDroiteGauche,
} from '../../../Utils/Utils';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import Layout from '../../../Layout';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import VideoSection from '../../../components/ContentType/Section/VideoSection/VideoSection';
import useMedias from '../../../hooks/useMedias';

import CardSection from '../../../components/ContentType/CardSection/CardSection';
import ContactInfo from '../../../components/ContentType/ContactInfo/ContactInfo';
import HomeColasInnov from '../../../components/HomeColasInnov/HomeColasInnov';
import VisuelComponent from '../../../components/ContentType/VisuelComponent/VisuelComponent';
import ArrowList from '../../../Molecules/ArrowList/ArrowList';
import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';
import QuoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import TextVisuelCTATwoCol from '../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import WebsiteList from '../../../components/WebsiteList/WebsiteList';
let classNames = require('classnames');

const ImplantationDetail = ({ data, pageContext }) => {
  const intl = useIntl();
  const { processUrl, getImage, getDocument } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const pageData = data?.pageData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  const documentsArray = data.allDocuments.edges;
  const [innerNavList, setInnerNavList] = useState([]);
  const [allText, setAllText] = useState('');

  const rtlBlock = pageData.relationships?.field_blocs?.find((block) => {
    return block?.field_display_rtl === true;
  });

  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });


  let sliders = [];
  let sectionArr = [];
  let leftRightBlocks = [];
  let sectionCount = 0;
  let globalText = '';
  let firstTitle = false;
  let firstRtlTitle = false;

  const slider_settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1, //no slider on desktop
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    setInnerNavList(sectionArr);
    setAllText(
      removeHtmlTag(
        `${pageData.title}.${pageData.field_sous_titre !== null &&
          pageData.field_sous_titre !== undefined
          ? pageData.field_sous_titre
          : ''
        }.${globalText}`
      )
    );
  }, []);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
        description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
        shareImage={processUrl(getImage(imagesArray, pageData.relationships?.field_image?.drupal_internal__mid)?.image_style_uri?.gatsby_medium)}
      />
      <div
        className={classNames(
          'page_template',
          'page_implementation_detail',
          !pageData?.field_padding && 'zero_bottom_padding'
        )}
      >
        <ScrollPageNav data={innerNavList} />
        <PageBanner
          visuel={getImage(
            imagesArray,
            pageData?.relationships?.field_image?.drupal_internal__mid
          )}
          alt={pageData.relationships?.field_image?.field_media_image?.alt || ''}
          scroll={true}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                parentPage: {
                  title: breadCrumb?.parent?.name,
                  url: breadCrumb?.parent?.link,
                },
                currentPage: {
                  title: breadCrumb?.current?.name,
                  url: breadCrumb?.current?.link,
                },
                locale: pageData.langcode,
              }}
            />
            <div
              className={classNames({
                title_audio:
                  allText &&
                  pageData.field_version_audio &&
                  allText.length > 30,
              })}
            >
              <TitlePage
                color="color_white"
                title={pageData.title}
                description={pageData.field_sous_titre}
              />
              {allText && pageData.field_version_audio && allText.length > 30 && (
                <AudioComponent
                  text={intl.formatMessage({
                    id: 'detailsActivities.button.listen.label',
                  })}
                  textToRead={allText}
                  color="color_white"
                  langCountryCode={pageContext['config_data_' + intl.locale]?.field_lang_and_countrycode}
                />
              )}
            </div>
          </div>
        </PageBanner>

        {pageData.relationships?.field_blocs?.map((block, i) => {
          if (pageData.field_progress) {
            if (block.field_anchor?.drupal_internal__target_id && sectionArr.length < 2) {
              sectionArr.push({
                title: !firstTitle ? "01. " + pageData.field_langue_principale : "02. " + pageData.field_langue_secondaire,
                section: 'scroll_to_' + block.field_anchor?.drupal_internal__target_id,
              });
              firstTitle = true;
            }
          }

          switch (block.__typename) {
            case 'block_content__block_texte':
              return (
                <section
                  className={classNames(
                    'section_content',
                    'block_content__block_texte',
                    'scroll_to_' + sectionCount,
                    (block.field_secondary_background ? 'zero_margin_bottom' : '')
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block?.field_backgroud_color)}
                    bottomInfinite={block?.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    secondaryColor={getSectionBG(block.field_secondary_background)}
                  >
                    {
                      block.relationships?.field_image?.drupal_internal__mid && block?.body?.processed ? (
                        <IntroPage
                          key={i}
                          sectionClass={null}
                          customClass='zero_margin'
                          title={block?.field_title?.processed}
                          subtitle={block?.field_subtitle?.processed}
                          visuel={getImage(
                            imagesArray,
                            block.relationships?.field_image?.drupal_internal__mid
                          )}
                          alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                          text={block?.body?.processed}
                          size="border"
                        />
                      ) : (
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={block?.field_title?.processed}
                            description={block?.field_subtitle?.processed}
                            type="arrow"
                            color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                          />
                        </div>
                      )
                    }

                    <div className="wrapper_page_xs">
                      {block?.relationships?.field_section.map((section, j) => {
                        globalText += `${section?.field_title?.processed != undefined &&
                          section?.field_title?.processed != null
                          ? section?.field_title?.processed
                          : ''
                          }.${section?.field_bloc_text_1?.processed
                          }.`;
                        return (
                          <TextVisuelTwoCol
                            key={j}
                            extremeLeft={
                              section?.field_no_padding &&
                                section?.field_alignment === 'left'
                                ? true
                                : false
                            }
                            extremeRight={
                              section?.field_no_padding &&
                                section?.field_alignment === 'right'
                                ? true
                                : false
                            }
                            orderInverse={
                              section?.field_alignment === 'right'
                                ? true
                                : false
                            }
                            extactHalf={
                              section?.field_alignment === 'left' ? true : false
                            }
                            visuel={getImage(
                              imagesArray,
                              section?.relationships?.field_image
                                ?.drupal_internal__mid
                            )}
                            alt={section?.relationships?.field_image?.field_media_image?.alt || ''}
                            title={section?.field_title?.processed}
                            colorTitle={'color_' + getTextColor(getSectionBG(block?.field_backgroud_color))}
                            titleIconColor={section.field_theme_title === 'theme_1' ? 'color_yellow' : 'color_dark_bleu'}
                            colorDescription={'color_' + getTextColor(getSectionBG(block?.field_backgroud_color))}
                            text={section?.field_bloc_text_1?.processed}
                            cta={{
                              ctaText: section?.field_link?.title,
                              ctaLink: section?.field_link?.url,
                              ctaArialLabel: section?.field_link?.title + (section?.field_title?.processed ? (' ' + intl.formatMessage({ id: 'common.about_something' }) + ' ' + section?.field_title?.processed) : ''),
                              ctaTarget: section?.field_nouvelle_fenetre
                            }}
                          />
                        );
                      })}
                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__basic':
              globalText += `${block.field_title?.processed}.${block.body?.processed}`;
              const webSiteDetails = {
                legend: block?.field_legend?.processed,
                field_sites_web: block?.relationships?.field_sites_web
              }
              return (
                <section
                  className={classNames(
                    'section_content',
                    'block_content__basic',
                    'scroll_to_' + sectionCount,
                    (block.field_secondary_background ? 'zero_margin_bottom' : '')
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    secondaryColor={getSectionBG(block.field_secondary_background)}
                  >
                    {(() => {
                      switch (block.field_appearance) {
                        case 'two_column_format':
                          return (
                            <div className="wrapper_page">
                              <TextVisuelCTATwoCol
                                extremeLeft={
                                  block?.field_no_padding &&
                                    block?.field_alignment === 'left'
                                    ? true
                                    : false
                                }
                                color={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                extremeRight={
                                  block?.field_no_padding &&
                                    block?.field_alignment === 'left'
                                    ? false
                                    : true
                                }
                                orderInverse={
                                  block?.field_alignment === 'right' ? true : false
                                }
                                visuel={getImage(
                                  imagesArray,
                                  block.relationships?.field_image?.drupal_internal__mid
                                )}
                                alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                                alignTop={true}
                                title={{
                                  titleSize: block.field_title_type === 'theme_1' ? 'h2' : 'h3',
                                  titleText: block.field_title?.processed,
                                  subtitle: block.field_subtitle?.processed,
                                }}
                                text={block.body.processed}
                                cta={{
                                  ctaText: block.field_link?.title,
                                  ctaLink: block.field_link?.url,
                                }}
                                rtl={block.field_display_rtl}
                              />
                            </div>
                          )
                        case 'two_column_format_title_outside':
                          return (
                            <div className="wrapper_page_xs">
                              {
                                block.field_title_type === 'theme_2' ?
                                  <TitleBlock
                                    title={block.field_title?.processed}
                                    subtitle={block.field_subtitle?.processed}
                                    rtl={block.field_display_rtl}
                                    color={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                    titleBackground={false}
                                  />
                                  :
                                  <TitleSection
                                    title={block.field_title?.processed}
                                    subtitle={block.field_subtitle?.processed}
                                    rtl={block.field_display_rtl}
                                    color={getTextColor(getSectionBG(block.field_backgroud_color))}
                                  />
                              }
                              <TextVisuelTwoCol
                                orderInverse={
                                  block.field_alignment === 'right'
                                    ? true
                                    : false
                                }
                                extremeLeft={
                                  block?.field_no_padding &&
                                    block?.field_alignment === 'left'
                                    ? true
                                    : false
                                }
                                extremeRight={
                                  block?.field_no_padding &&
                                    block?.field_alignment === 'right'
                                    ? true
                                    : false
                                }
                                visuel={getImage(
                                  imagesArray,
                                  block?.relationships?.field_image?.drupal_internal__mid
                                )}
                                alt={block?.field_title?.processed}
                                text={block.body.processed}
                                colorTitle={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                colorDescription={'color_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                                rtl={block.field_display_rtl}
                              />
                            </div>
                          )
                        default:
                          return (
                            <div className="wrapper_page_xs">
                              <BlockSimpleTextVisuelButtonWebsite
                                data={block}
                                imagesArray={imagesArray}
                                rtl={block.field_display_rtl}
                              />
                            </div>
                          )
                          break;
                      }
                    })()}
                    <div className="wrapper_page">
                      {webSiteDetails?.field_sites_web?.length > 0 &&
                        <WebsiteList
                          componentData={webSiteDetails}
                          imagesArray={imagesArray}
                          alignment={data.imageAlignment}
                          color={getTextColor(getSectionBG(block.field_backgroud_color))}
                          rtl={block.field_display_rtl}
                        />
                      }
                    </div>

                  </ShapeBackground>
                </section>
              );
            case 'block_content__block_innovation__':
              return (
                <section
                  className={classNames(
                    'section_content',
                    'block_content__block_innovation__',
                    'scroll_to_' + sectionCount
                  )}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    arrowRightWithNoMarge={true}
                  >
                    <HomeColasInnov
                      data={block}
                      key={i}
                      imagesArray={imagesArray}
                      rtl={block.field_display_rtl}
                    />
                  </ShapeBackground>
                </section>
              );

            // case 'block_content__block_slider_nos_activites':
            case 'block_content__block_slider_nos_activites': //Block Slider de contenus existant
              let slides = [];
              globalText += `${block.field_title?.processed}.${block.body?.processed}`;
              block.relationships?.field_nos_activites?.forEach((item) => {
                slides.push({
                  image: getImage(
                    imagesArray,
                    item?.relationships?.field_image?.drupal_internal__mid
                  ),
                  info: {
                    text: item.title,
                    description: item.body?.summary,
                    link: item.field_link?.uri ? item.field_link?.uri : '/' + item.path?.langcode + item.path?.alias,
                    link_label: block.field_link_title,
                  },
                });
              });
              return (
                <section
                  className={classNames(
                    'section_content',
                    'block_content__block_slider_nos_activites',
                    'scroll_to_' + sectionCount,
                    (block.field_secondary_background ? 'zero_margin_bottom' : '')
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    secondaryColor={getSectionBG(block.field_secondary_background)}
                  >
                    <div className={classNames('wrapper_page_xs')}>
                      <TitleSection
                        title={block.field_title?.processed}
                        subtitle={block.field_subtitle?.processed}
                        description={block.body?.processed}
                        type="arrow"
                        color={getTextColor(getSectionBG(block.field_backgroud_color))}
                      />
                    </div>
                    <div
                      className={classNames('wrapper_page_xs', 'only_desktop')}
                    >
                      <Carousel
                        slides={getReOrderedCarouselSlides(slides)}
                        textColor={getTextColor(getSectionBG(block.field_backgroud_color))}
                        openVideo={loadVideo}
                      />
                    </div>

                    <div className={classNames('only_mobile_tablet')}>
                      <SliderComponent settings={slider_settings}>
                        {slides.map((slide, index) => (
                          <div key={index}>
                            <div className="only_mobile">
                              <div className="image-wrapper">
                                <img
                                  src={processUrl(slide.image?.image_style_uri?.gatsby_profile_thumbnail)}
                                  alt={`slide-${index}`}
                                  style={{
                                    width: '100%',
                                    height: '123vw',
                                    maxHeight: '510px',
                                  }}
                                />
                              </div>
                            </div>
                            <div className="only_tablet">
                              <img
                                src={processUrl(slide.image?.image_style_uri?.gatsby_profile_thumbnail)}
                                alt={`slide-${index}`}
                                style={{ objectFit: 'cover' }}
                              />
                            </div>
                            <div className="info_title_description">
                              <p>{slide.info.text}</p>
                              {slide.info.link && (
                                <Button
                                  primary={true}
                                  label={slide.info.link_label}
                                  link={slide.info.link}
                                  aria-label={slide.info.link_label}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </SliderComponent>
                    </div>
                  </ShapeBackground>
                </section>
              );
            case 'block_content__block_solutions':
              return <section
                className={classNames(
                  'section_content',
                  'block_content__block_solutions',
                  'scroll_to_' + sectionCount
                )}
                key={i}
              >
                <ShapeBackground
                  top={tradDroiteGauche(block?.field_position_bordure_haut)}
                  color={getSectionBG(block.field_backgroud_color)}
                  bottomInfinite={block.field_infinite_border}
                  bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                >
                  <CardSection
                    imagesArray={imagesArray}
                    data={block}
                    color={getTextColor(getSectionBG(block.field_backgroud_color))}
                    bgColor={getSectionBG(block.field_backgroud_color)}
                  />
                </ShapeBackground>
              </section>;

            case 'block_content__block_contact_rail':
              return (
                <section
                  className={classNames(
                    'section_content',
                    'block_2_section',
                    'scroll_to_' + sectionCount
                  )}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                    arrowRightWithNoMarge={true}
                  >
                    <ContactInfo
                      data={block}
                      color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                    />

                  </ShapeBackground>

                </section>
              )
            case 'block_content__block_citation':
              globalText += block?.field_citation + '.';
              return (
                <section className={classNames('section_content')} key={i}>
                  <div className="quotemark_container">
                    <QuoteMark
                      lang={block.langcode}
                      size="m"
                      textWeight="m"
                      text={block?.field_citation}
                    />
                  </div>
                </section>
              );
            case 'block_content__block_chiffres_clefs':
              const dataArr = [];
              block.relationships?.field_values?.forEach((item) => {
                dataArr.push({
                  number: item.field_chiffre,
                  title: item.field_legend?.processed,
                });
              });

              return (
                <KeyNumbers
                  key={i}
                  title={block.field_title?.processed}
                  subtitle={block.body?.processed?.replace(/<p[^>]*>/g, '')}
                  sectionName={'scroll_to_' + sectionCount}
                  sectionClass='block_content__block_chiffres_clefs'
                  textColor="white"
                  NoPadding={pageData.relationships?.field_blocs[i - 1]?.field_position_bordure_bas != 'droite' ? true : false}
                  behindBorder={pageData.relationships?.field_blocs[i - 1]?.field_position_bordure_bas === 'droite' ? true : false}
                  backgroundImg={getImage(
                    imagesArray,
                    block.relationships?.field_image?.drupal_internal__mid
                  )}
                  datas={dataArr}
                  locale={block.langcode}
                />
              );

            case 'block_content__block_video':
              const videoContent = {
                content: [
                  {
                    type: 'video',
                    link: block.field_link?.uri,
                    title: removeHtmlTag(block.field_title?.processed),
                    image: getImage(
                      imagesArray,
                      block.relationships?.field_image?.drupal_internal__mid
                    ),
                    image_alt: block.relationships?.field_image?.field_media_image?.alt || '',
                    description: block.body?.processed
                  },
                ],
              };
              return (
                <section className={'scroll_to_' + sectionCount}>
                  <VideoSection imagesArray={imagesArray} videoDetails={videoContent}></VideoSection>;
                </section>
              )

            case 'block_content__block_document':
              let tempFileArr = [];
              block.relationships?.field_file.forEach((file) => {
                tempFileArr.push(
                  getDocument(documentsArray, file?.drupal_internal__mid)
                );
              });
              const documentContent = {
                year: block.node?.relationships?.field_year?.name,
                title: removeHtmlTag(block?.field_title?.processed),
                subtitle: removeHtmlTag(block?.field_subtitle?.processed),
                category: block?.field_category,
                content: [
                  {
                    type: 'document',
                    files: tempFileArr,
                  },
                ],
              };

              return (
                <DocumentSection
                  documentDetails={documentContent}
                  titleSection={true}
                  sectionClass='block_content__block_document'
                  customClass={'scroll_to_' + sectionCount}
                  key={i}
                ></DocumentSection>
              );

          }
          sectionCount++;
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query implantationDetailQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    pageData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          field_type
          field_padding
          field_sous_titre
          field_version_audio
          field_progress
          field_langue_principale
          field_langue_secondaire
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          path {
            alias
            langcode
          }
          relationships {
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__basic {
                  id
                  field_display_rtl
                  field_backgroud_color
                  field_secondary_background
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_no_padding
                  field_alignment
                  field_appearance
                  field_subtitle {
                    processed
                  }
                  field_title_type
                  field_title {
                    processed
                  }
                  body{
                    processed
                  }
                  field_legend {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_sites_web {
                      field_text {
                        processed
                      }
                      field_subtitle
                      field_link {
                        url
                      }
                      field_theme_style
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                  field_link {
                    title
                    url
                  }
                }
                ... on block_content__block_innovation {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_display_rtl
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  
                  relationships {
                    field_slides {
                      field_publish
                      field_title {
                        processed
                      }
                      field_link {
                        title
                        url
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                      langcode
                    }
                  }
                }
                ... on block_content__block_texte {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_secondary_background
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_display_rtl
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_section {
                      field_no_padding
                      field_alignment
                      field_theme_title
                      field_nouvelle_fenetre
                      field_title {
                        processed
                      }
                      field_bloc_text_1 {
                        processed
                      }                
                      field_link {
                        title
                        url
                      }
                      field_nouvelle_fenetre
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_slider_nos_activites {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  field_link_title
                  field_infinite_border
                  field_backgroud_color
                  field_secondary_background
                  field_position_bordure_haut
                  field_position_bordure_bas
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__page{
						              title
                          body{
						                summary
                          }
                          path{
                            alias
                            langcode
                          }
                          relationships{
                            field_image{
                              drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_solutions{
                  field_title{
                    processed
                  }
                  field_subtitle{
                    processed
                  }
                  field_display_rtl
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_backgroud_color
                  relationships{
                    field_blocs{
                      field_subtitle
                      field_text{
                        processed
                      }
                      field_bloc_text_1{
                        processed
                      }
                      relationships{
                        field_image{
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                          field_alt
                        }
                      }
                      field_link{
                        title
                        url
                      }
                    }
                  }
                  
                }
                ... on block_content__block_contact_rail{
                  field_title{
                    processed
                  }
                  field_subtitle{
                    processed
                  }
                  field_display_rtl
                  field_backgroud_color
                  field_infinite_border
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_texte_multiples{
                    processed
                  }
                }
                ... on block_content__block_citation {
                  field_citation
                  langcode
                }
                ... on block_content__block_chiffres_clefs {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  langcode
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_values {
                      field_legend {
                        processed
                      }
                      field_chiffre
                    }
                  }
                }
                ... on block_content__block_video {
                  id
                  field_title {
                    processed
                  }
                  field_link {
                    uri
                  }
                  body {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_document {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_background
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
          }
          body {
            processed
          }
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
  }
`;

export default ImplantationDetail;
